import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import './shared-style.css';
import Loader from './website_widgets/Loader';
import Header from './header/Header';
import Footer from './footer/Footer';
import SubTopicPage from './pages/subTopicPage/SubTopicPage';

import {
  lifeStory,
  friends,
  travel,
  hobbies,
  eulogy_2019,
  eulogy_2020,
  eulogy_2021,
  eulogy_2022,
  eulogy_2023,
  eulogy_2024,
  memorial,
  eulogy_moniPaper,
  inHisMemoryPage,
} from '../api/topicData';
// import ImgsNumContextProvider from './hooks/ImgsNumContext';
// import DetailsSizeContextProvider from './hooks/DetailsSizeContext';

const HomePage = lazy(() => import('./pages/homePage/HomePage'));
const ContactPage = lazy(() => import('./pages/contactPage/ContactPage'));
const DayAfterPage = lazy(() => import('./pages/dayAfterPage/DayAfterPage'));
const TopicPage = lazy(() => import('./pages/topicPage/TopicPage'));
// const SubTopicPage = lazy(() => import('./pages/subTopicPage/SubTopicPage'));
const FriendsRememberPage = lazy(() =>
  import('./pages/dayAfterPage/friendsRememberPage/FriendsRememberPage'),
);
const EulogyPage = lazy(() =>
  import('./pages/dayAfterPage/eulogyPage/EulogyPage'),
);
const VideosPage = lazy(() =>
  import('./pages/dayAfterPage/videosPage/VideosPage'),
);
const FriendRemember = lazy(() =>
  import('./pages/dayAfterPage/friendsRememberPage/FriendRemember'),
);
const EulogyTitle = lazy(() =>
  import('./pages/dayAfterPage/eulogyPage/EulogyTitle'),
);
const EulogyItself = lazy(() =>
  import('./pages/dayAfterPage/eulogyPage/EulogyItself'),
);
const NotFound = lazy(() => import('./pages/NotFound'));

const App = () => {
  return (
    // <DetailsSizeContextProvider>
    //   <ImgsNumContextProvider>
    <div className='app-container'>
      <Header />
      <main>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path='/' exact component={HomePage} />
            <Route path='/contact' component={ContactPage} />
            <Route path='/dayAfter' exact component={DayAfterPage} />
            <Route path='/dayAfter/eulogy' exact component={EulogyPage} />
            <Route
              path='/dayAfter/inHisMemory'
              exact
              component={() => <TopicPage topic={inHisMemoryPage} />}
            />
            <Route
              path='/dayAfter/memorial'
              exact
              component={() => <EulogyItself topic={memorial} />}
            />
            <Route path='/dayAfter/videos' component={VideosPage} />
            <Route
              path='/dayAfter/friendsRemember'
              exact
              component={FriendsRememberPage}
            />
            <Route
              path='/dayAfter/eulogy/2019'
              exact
              component={() => <EulogyTitle topic={eulogy_2019} />}
            />
            <Route
              path='/dayAfter/eulogy/2020'
              exact
              component={() => <EulogyTitle topic={eulogy_2020} />}
            />
            <Route
              path='/dayAfter/eulogy/2021'
              exact
              component={() => <EulogyTitle topic={eulogy_2021} />}
            />
            <Route
              path='/dayAfter/eulogy/2022'
              exact
              component={() => <EulogyTitle topic={eulogy_2022} />}
            />
            <Route
              path='/dayAfter/eulogy/2023'
              exact
              component={() => <EulogyTitle topic={eulogy_2023} />}
            />
            <Route
              path='/dayAfter/eulogy/2024'
              exact
              component={() => <EulogyTitle topic={eulogy_2024} />}
            />
            <Route
              path='/dayAfter/eulogy/moniPaper'
              component={() => <EulogyTitle topic={eulogy_moniPaper} />}
            />
            <Route
              path='/dayAfter/eulogy/2019/:title'
              component={(matchProps) => (
                <EulogyItself {...matchProps} topic={eulogy_2019} />
              )}
            />
            <Route
              path='/dayAfter/eulogy/2020/:title'
              component={(matchProps) => (
                <EulogyItself {...matchProps} topic={eulogy_2020} />
              )}
            />
            <Route
              path='/dayAfter/eulogy/2021/:title'
              component={(matchProps) => (
                <EulogyItself {...matchProps} topic={eulogy_2021} />
              )}
            />
            <Route
              path='/dayAfter/eulogy/2022/:title'
              component={(matchProps) => (
                <EulogyItself {...matchProps} topic={eulogy_2022} />
              )}
            />
            <Route
              path='/dayAfter/eulogy/2023/:title'
              component={(matchProps) => (
                <EulogyItself {...matchProps} topic={eulogy_2023} />
              )}
            />
            <Route
              path='/dayAfter/eulogy/2024/:title'
              component={(matchProps) => (
                <EulogyItself {...matchProps} topic={eulogy_2024} />
              )}
            />
            <Route
              path='/galleries/lifeStory'
              exact
              component={() => <TopicPage topic={lifeStory} />}
            />
            <Route
              path='/galleries/friends'
              exact
              component={() => <TopicPage topic={friends} />}
            />
            <Route
              path='/galleries/travel'
              exact
              component={() => <TopicPage topic={travel} />}
            />
            <Route
              path='/galleries/hobbies'
              exact
              component={() => <TopicPage topic={hobbies} />}
            />
            <Route
              path='/galleries/lifeStory/:subTopic'
              component={(matchProps) => (
                <SubTopicPage {...matchProps} topic={lifeStory} />
              )}
            />
            <Route
              path='/galleries/friends/:subTopic'
              component={(matchProps) => (
                <SubTopicPage {...matchProps} topic={friends} />
              )}
            />
            <Route
              path='/galleries/travel/:subTopic'
              component={(matchProps) => (
                <SubTopicPage {...matchProps} topic={travel} />
              )}
            />
            <Route
              path='/galleries/hobbies/:subTopic'
              component={(matchProps) => (
                <SubTopicPage {...matchProps} topic={hobbies} />
              )}
            />
            <Route
              path='/dayAfter/inHisMemory/:subTopic'
              component={(matchProps) => (
                <SubTopicPage {...matchProps} topic={inHisMemoryPage} />
              )}
            />
            <Route
              path='/dayAfter/friendsRemember/:friend'
              component={FriendRemember}
            />
            <Route path='*' component={NotFound} />
          </Switch>
        </Suspense>
      </main>
      <Footer />
    </div>
    //   </ImgsNumContextProvider>
    // </DetailsSizeContextProvider>
  );
};

export default App;
