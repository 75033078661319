import React from 'react';
import ReactDom from 'react-dom';
import './modalImage.css';

const ModalImage = ({ open, close, subTopicImages, img }) => {
  if (!open) return null;

  const imgIndex = subTopicImages.indexOf(img);

  let carouselItems = subTopicImages.map((image, index) => {
    const clicked = index === imgIndex ? 'active' : '';
    return (
      <div
        className={`carousel-item modal__container-inner ${clicked}`}
        data-interval='false'
        key={index}
      >
        <img src={image} className='rounded img-thumbnail' alt='...' />
      </div>
    );
  });

  return ReactDom.createPortal(
    <div>
      {/* backdrop */}
      <div onClick={close} className='backdrop' />
      {/* backdrop */}

      <div className='modal__container'>
        <span className='close' onClick={close}>
          &times;
        </span>

        <div
          id='modalCarouselControls'
          className='carousel slide'
          from='imgIndex'
          data-ride='carousel'
        >
          {/* Images */}
          <div className='carousel-inner text-center'>{carouselItems}</div>
          {/* Images */}

          {/* Controls */}
          <a
            className='carousel-control-prev'
            href='#modalCarouselControls'
            role='button'
            data-slide='prev'
          >
            <span className='carousel-control-prev-icon' aria-hidden='true' />
            <span className='sr-only'>Previous</span>
          </a>
          <a
            className='carousel-control-next'
            href='#modalCarouselControls'
            role='button'
            data-slide='next'
          >
            <span className='carousel-control-next-icon' aria-hidden='true' />
            <span className='sr-only'>Next</span>
          </a>
          {/* Controls */}
        </div>
      </div>
    </div>,
    document.getElementById('portal'),
  );
};

export default ModalImage;
