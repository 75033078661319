import React, { useState } from 'react';
import {
  Image,
  // Transformation,
  //   CloudinaryContext,
} from 'cloudinary-react';
import ModalImage from './ModalImage';

//gets one section of subTopic/FriendRemember images and renders them
const ImagesSection = ({ images, subTopicImages }) => {
  // console.log(subTopicImages)
  const [isOpen, setIsOpen] = useState(false);
  const [activeImg, setActiveImg] = useState('');

  const closeModalHandler = () => setIsOpen(false);

  const onButtonClick = (image) => {
    setIsOpen(true);
    setActiveImg(image);
  };

  const renderImages = images.map((image) => {
    return (
      <div className='subTopicPage__img-container' key={Math.random()}>
        {/* <img className="subTopicPage__img" src={image} onClick={() => onButtonClick(image)} alt="..." /> */}
        <Image
          cloudName='yonatan-memorial'
          secure='true'
          upload_preset='my_unsigned_preset'
          publicId={image}
          className='subTopicPage__img'
          onClick={() => onButtonClick(image)}
        />
        {/* <Transformation width='400' height='250' gravity='south' crop='fill' /> */}
        {/* </Image> */}
      </div>
    );
  });

  return (
    <div className='subTopicPage__section-imgs'>
      <ModalImage
        open={isOpen}
        close={closeModalHandler}
        subTopicImages={subTopicImages}
        img={activeImg}
      />
      {renderImages}
    </div>
  );
};

export default ImagesSection;
