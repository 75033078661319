import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './components/App';
import ScrollToTop from './components/website_widgets/ScrollToTop';
import ScrollToTopButton from './components/website_widgets/ScrollToTopButton';
import { ViewportProvider } from './components/website_widgets/use-viewport';

ReactDOM.render(
  <BrowserRouter>
    <ViewportProvider>
      <ScrollToTopButton showBelow={250} />
      <ScrollToTop />
      <App />
    </ViewportProvider>
  </BrowserRouter>,
  document.querySelector('#root'),
);
