import React from 'react';
// import '../../styles/subTopicPage.css';

//gets the details of a section
const DetailsSection = ({ description }) => {
  return (
    <div className='subTopicPage__section-desc'>
      <p dir='auto' dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default DetailsSection;
