import React, { createContext, useContext, useEffect, useState } from 'react';

const ViewportContext = createContext({
    isMobile: window.innerWidth < 575,
    height: window.innerHeight,
    width: window.innerWidth
})

export const ViewportProvider = ({ children }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 575)
    // sizes: -575, 576-767, 768-1199, 1200-
    const [height, setHeight] = useState(window.innerHeight)
    const [width, setWidth] = useState(window.innerWidth)

    const handleResize = () => {
        setIsMobile(window.innerWidth < 575)
        setHeight(window.innerHeight)
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <ViewportContext.Provider value={{ isMobile, height, width }}>
            {children}
        </ViewportContext.Provider>
    )
}

export function useViewport() {
    return useContext(ViewportContext)
}