// upload/c_fill,f_auto,q_auto:low,w_1000/

export const homePageCarouselImages = [
  'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low/HomePage/carousel_yonatan_1_faeqfp.jpg',
  'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low/HomePage/carousel_yonatan_2_zcameb.jpg',
  'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low/HomePage/carousel_yonatan_3_lcaxpf.jpg',
  'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low/HomePage/carousel_yonatan_4_oozrn2.jpg',
  'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low/HomePage/carousel_yonatan_5_nipaop.jpg',
  'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low/HomePage/carousel_yonatan_6_gxqgku.jpg',
  'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low/HomePage/carousel_yonatan_7_o6xfvx.jpg',
  'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low/HomePage/carousel_yonatan_8_ayd1pq.jpg',
  'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low/HomePage/carousel_yonatan_9_sbcfwn.jpg',
];

export const lifeStoryImages = {
  //refactor to Array of Arrays?
  firstYears: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-7.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-9.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_1_first_years_1982_1986-12.jpg',
    ],
    [],
  ],
  elementarySchool: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-9.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-12.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_2_elementary_school_1992_1998-13.jpg',
    ],
    [],
  ],
  highSchool: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_3_high_1998_2004-12.jpg',
    ],
  ],
  army: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-3.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-10.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-16.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-17.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-13.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-14.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-12.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-15.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-18.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_2004_2007-19.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_reserves_2008_2018-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_reserves_2008_2018-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_reserves_2008_2018-8.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/video/upload/1_life_story_4_army_reserves_2008_2018-7.mp4',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_reserves_2008_2018-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_reserves_2008_2018-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_reserves_2008_2018-3.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_reserves_2008_2018-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_reserves_2008_2018-6.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_reserves_2008_2018-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_4_army_reserves_2008_2018-5.jpg',
    ],
  ],
  jerusalem: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-6.jpg',
    ],
    [
      // video about the bet
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-9.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-17.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-12.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-16.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-14.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_5_jerusalem_study_2011_2015-15.jpg',
    ],
    [],
  ],
  telAviv: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_6_tel_aviv_study_2015_2019-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_6_tel_aviv_study_2015_2019-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_6_tel_aviv_study_2015_2019-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_6_tel_aviv_study_2015_2019-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_6_tel_aviv_study_2015_2019-15.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_6_tel_aviv_study_2015_2019-6.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_6_tel_aviv_study_2015_2019-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_6_tel_aviv_study_2015_2019-12.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/v1622053657/1_life_story_6_tel_aviv_study_2015_2019-16.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_6_tel_aviv_study_2015_2019-14.jpg',
    ],
  ],
  work: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-2.jpg',
    ],
    [
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-3.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-4.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-17.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-18.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-19.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-16.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-10.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-11.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-12.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-13.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-14.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/1_life_story_7_work_family_home-15.jpg',
    ],
  ],
};

export const friendsImages = {
  //refactor to Array of Arrays?
  brothersInScool: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-1.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-21.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-22.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-24.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-25.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-23.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-26.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-27.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-28.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-7.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-33.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-29.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-11.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-12.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-30.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-13.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-14.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-37.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-39.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-36.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-15.jpg',
      // 16- video
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-17.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-32.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-31.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-18.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-41.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-47.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-48.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-42.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-19.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-34.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-31.jpg',
    ],
    // [
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-38.jpg',
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-44.jpg',
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-35.jpg',
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-45.jpg',
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-43.jpg',
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-46.jpg',
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_1_brothers_friends-40.jpg',
    //   // 21- video
    // ],
  ],

  // brothers: [
  // 	['http://drive.google.com/uc?export=view&id=1ghQSgNjjVeis3u9plK2JVr3WjZYy8V7L'],
  // 	['http://drive.google.com/uc?export=view&id=1IMhEH3Rn3DPQcleycBFID8Sof7BI1OpK']
  // ],
  otherFriend: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves_gabi-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves_gabi-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves_gabi-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves_gabi-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_1_army_reserves_gabi-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_2_bachelor_degree-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_2_bachelor_degree-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_2_bachelor_degree-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_2_bachelor_degree-10.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_2_bachelor_degree-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_2_bachelor_degree-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_2_bachelor_degree-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_2_bachelor_degree-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_2_bachelor_degree-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_2_bachelor_degree-6.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_3_midbern-1.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_3_midbern-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_3_midbern-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_4_ran_goren-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_4_ran_goren-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_4_ran_goren-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_4_ran_goren-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_4_ran_goren-5.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_5_eyal_aizic-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_5_eyal_aizic-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_5_eyal_aizic-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_5_eyal_aizic-5.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_7_amit_aizic-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_8_more_freinds-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_8_more_freinds-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_8_more_freinds-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_8_more_freinds-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/2_friends_3_others_8_more_freinds-5.jpg',
    ],
  ],
};

export const travelImages = {
  //refactor to Array of Arrays?
  bigTrip: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_north-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_north-1.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_north-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_north-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_north-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_south-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_north-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_south-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_south-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_south-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_south-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_south-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_1_new_zealand_south-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-10.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-12.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_2_australia-13.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_3_hong_kong-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_3_hong_kong-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_3_hong_kong-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_3_hong_kong-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_4_china-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_4_china-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_4_china-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_4_china-3.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_4_china-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_4_china-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_4_china-7.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_4_china-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/v1635192676/3_abroad_1_the_big_trip_4_china-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_4_china-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/v1620416273/3_abroad_1_the_big_trip_4_china-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_4_china-12.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-12.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_5_mongolia-9.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_6_beijing-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_6_beijing-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_6_beijing-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_6_beijing-5.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_7_india-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_7_india-1.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_7_india-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_7_india-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_7_india-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_7_india-7.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_7_india-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/v1635797143/3_abroad_1_the_big_trip_7_india-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_7_india-10.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_7_india-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_7_india-9.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-12.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/v1635797395/3_abroad_1_the_big_trip_8_nepal-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_8_nepal-9.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_9_andaman-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_9_andaman-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_9_andaman-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_9_andaman-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_9_andaman-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_9_andaman-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_9_andaman-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_9_andaman-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_9_andaman-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_1_the_big_trip_9_andaman-5.jpg',
    ],
  ],
  africa: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda-8.jpg',
    ],
    // [
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda_sipi_falls-2.jpg',
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda_sipi_falls-1.jpg',
    // ],
    // [
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda_sipi_falls-3.jpg',
    // ],
    // [
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda_sipi_falls-4.jpg',
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_1_uganda_sipi_falls-5.jpg',
    // ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_2_kenya-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_2_kenya-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_2_kenya-10.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_2_kenya-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_2_kenya-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_2_kenya-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_2_kenya-7.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_3_tanzania-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_3_tanzania-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_3_tanzania-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_3_tanzania-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_3_tanzania-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_3_tanzania-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_3_tanzania-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_3_tanzania-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_3_tanzania-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_3_tanzania-11.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_4_malawi-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_4_malawi-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_4_malawi-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_4_malawi-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_4_malawi-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_4_malawi-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_4_malawi-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_4_malawi-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_4_malawi-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_4_malawi-12.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_5_zanzibar-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_5_zanzibar-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_5_zanzibar-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_5_zanzibar-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_5_zanzibar-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_5_zanzibar-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_5_zanzibar-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_5_zanzibar-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_5_zanzibar-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_5_zanzibar-10.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_6_ethiopia-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_2_africa_6_ethiopia-2.jpg',
    ],
  ],
  europeFirst: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_1_ cologne-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_1_ cologne-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_1_ cologne-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_1_ cologne-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_1_ cologne-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_1_ cologne-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_1_ cologne-7.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_1_london-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_1_london-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_1_london-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_1_london-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_1_london-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_2_scotland-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_2_scotland-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_2_scotland-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_2_scotland-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_2_scotland-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_2_scotland-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_2_scotland-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_2_scotland-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_2_scotland-11.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_3_ireland-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_3_ireland-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_3_ireland-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_3_ireland-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_3_ireland-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_3_ireland-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_3_ireland-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_3_ireland-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_3_ireland-9.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_4_england-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_4_england-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_4_england-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_4_england-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_4_england-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_4_england-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_4_england-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_2_england_ireland_4_england-6.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_3_berlin-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_3_berlin-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_3_berlin-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_3_berlin-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_3_berlin-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_3_berlin-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_3_berlin-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_3_berlin-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_3_europe_3_berlin-9.jpg',
    ],
  ],
  georgia: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_4_georgia-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_4_georgia-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_4_georgia-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_4_georgia-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_4_georgia-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_4_georgia-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_4_georgia-6.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_4_georgia-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_4_georgia-12.jpg',
    ],
  ],
  australia: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-7.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-5.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-9.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-10.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-11.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-12.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-13.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_2_tasmania-12.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_3_sydney-1.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_3_sydney-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_3_sydney-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_3_sydney-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-12.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_4_west-10.jpg',
    ],
    [],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_2_new_zealand-12.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-14.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-16.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_5_australia_new_zealand_1_student_exchange_1_student_life-15.jpg',
    ],
    [],
  ],
  greece: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_1_greece-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_1_greece-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_1_greece-3.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_1_greece-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_1_greece-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_1_greece-6.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_1_greece-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_1_greece-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_1_greece-7.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_2_netherlands-1.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_2_netherlands-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_2_netherlands-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_6_greece_netherlands_2_netherlands-3.jpg',
    ],
    [],
  ],
  usa: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-10.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-12.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-13.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-14.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-16.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_1_adam_and_me-15.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_2_wedding-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_2_wedding-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_2_wedding-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_2_wedding-6.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_2_wedding-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_2_wedding-4.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-1.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-11.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-13.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-14.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-12.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_3_with_the_guys-15.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_4_vermont_and_nh-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_4_vermont_and_nh-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_4_vermont_and_nh-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_4_vermont_and_nh-6.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_5_boston_and_nyc-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_5_boston_and_nyc-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_5_boston_and_nyc-2.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_5_boston_and_nyc-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_5_boston_and_nyc-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_5_boston_and_nyc-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_5_boston_and_nyc-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_5_boston_and_nyc-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_7_usa_5_boston_and_nyc-11.jpg',
    ],
  ],
  europeSecond: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_1_london-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_1_london-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_1_london-7.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_1_london-1.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_1_london-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_1_london-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_1_london-8.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_1_london-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_1_berlin-1.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_1_berlin-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_1_berlin-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_1_berlin-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-1.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-4.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-6.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-9.jpg',
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-10.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-12.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-13.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-14.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_1_berlin_france_switzerland_2_france_switzerland-15.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_2_italy-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_2_italy-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_2_italy-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_2_italy-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_2_italy-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_2_italy-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_2_berlin_france_switzerland_italy_2_italy-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_3_munich-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_3_munich-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_8_europe_quick_3_munich-4.jpg',
    ],
    [],
  ],
  southAmerica: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-8.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-7.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-10.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-11.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-12.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-13.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-14.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-15.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-16.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-18.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-20.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/v1638560234/3_abroad_9_south_america_1_from_polar_steps-17.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-19.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-21.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-22.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-23.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-24.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-25.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-26.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-27.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_1_from_polar_steps-28.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_2-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_2-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_2-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_2-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_2-5.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_2-6.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_4-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_4-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_4-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_3-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_3-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_3-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/3_abroad_9_south_america_3-4.jpg',
    ],
  ],
};

export const hobbiesImages = {
  //refactor to Array of Arrays?
  sport: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-6.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-7.jpg',
    ],
    // [],
    // [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-9.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-10.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-11.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-12.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-14.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-13.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-17.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_1_sport-15.jpg',
    ],
  ],
  scouts: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_2_scouts-5.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_2_scouts-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_2_scouts-1.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_2_scouts-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_2_scouts-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_2_scouts-6.jpg',
    ],
  ],
  midburn: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-11.jpg',
    ],
    [
      // 'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-12.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-4.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-12.jpg',
    ],
    // [
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-1.jpg',
    //   'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-6.jpg',
    // ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-10.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-9.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/4_hobbies_3_midbern-8.jpg',
    ],
  ],
};

// Also add in inHisMemoryDesc
export const inHisMemoryImages = {
  memoryEvening: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/v1642273411/5_memorial_2_events_1_evening_29.6.2019-1.png',
    ],
    [],
  ],
  burningMan: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_2_burning_man-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_2_burning_man-4.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_2_burning_man-3.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_2_burning_man-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_2_burning_man-5.jpg',
    ],
    [],
  ],
  aizicTrip: [
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/v1645474483/5_memorial_2_events_3_eyal_aizic-2.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_3_eyal_aizic-3.jpg',
    ],
  ],
  midburn2021: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_5_midbern_2021-2.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_5_midbern_2021-3.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_5_midbern_2021-1.jpg',
    ],
  ],
  midburn2022: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_5_midbern_2022-1.jpg',
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_5_midbern_2022-2.jpg',
    ],
  ],
  maccabiVsMarmorek: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_6_maccabi_marmorek-1.jpg',
    ],
  ],
  annualEvents: [
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/5_memorial_2_events_7_annualEvents.jpg',
    ],
    [],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/DayAfter/memorial_invite_2022.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/DayAfter/memorial_invite_2023.jpg',
    ],
    [
      'https://res.cloudinary.com/yonatan-memorial/image/upload/c_fill,f_auto,q_auto:low,w_1000/v1716142918/DayAfter/annualEvents_2024.jpg',
    ],
    [], // 2019 birthday
    [], // 2020 birthday
    [], // 2021 birthday
    [], // 2022 birthday
    [], // 2023 birthday
  ],
};
