import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const DropdownLink = ({ content, location, last }) => {
	const isLast = last ? 'dont-show' : '';

	return (
		<Fragment>
			<Link className="dropdown-item" to={location}>
				{content}
			</Link>
			<div className={`dropdown-divider ${isLast}`} />
		</Fragment>
	);
};

export default DropdownLink;
