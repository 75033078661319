import React from 'react';
import DetailsSection from '../topicPage/DetailsSection';
// import Loader from '../../website_widgets/Loader'
// const ImagesSection = lazy(() => import('./ImagesSection'));
import ImagesSection from './ImagesSection';

const CreateSections = ({ index, description, images, subTopicImages }) => {
  const place = index % 2 === 0 ? 'first' : 'last';
  // console.log(images);
  // console.log(images.length);
  const noImg = images.length === 0 ? '' : 'col-xl-5 order-last order-xl-first';
  const noImgDesc =
    images.length === 0 ? `col-xl-7` : `col-xl-5 order-first order-xl-${place}`;

  return (
    <section className='container-fluid subTopicPage__section'>
      <div className='subTopicPage__section-row'>
        <div className={noImgDesc}>
          <div className='row justify-content-center'>
            <div className='col-11 col-lg-10 mb-3'>
              <DetailsSection description={description} />
            </div>
          </div>
        </div>
        {/* <Suspense fallback={<Loader />}> */}
        <div className={noImg}>
          <ImagesSection images={images} subTopicImages={subTopicImages} />
        </div>
        {/* </Suspense> */}
      </div>
    </section>
  );
};

export default CreateSections;
