export const navDropdownLinks = {
  lifeStory: [
    { content: 'השנים הראשונות', location: '/galleries/lifeStory/firstYears' },
    {
      content: 'תקופת בי"ס יסודי',
      location: '/galleries/lifeStory/elementarySchool',
    },
    {
      content: 'תקופת חט"ב ותיכון',
      location: '/galleries/lifeStory/highSchool',
    },
    { content: 'צבא', location: '/galleries/lifeStory/army' },
    { content: 'ירושלים', location: '/galleries/lifeStory/jerusalem' },
    { content: 'תל אביב', location: '/galleries/lifeStory/telAviv' },
    { content: 'עבודה משפחה ובית', location: '/galleries/lifeStory/work' },
  ],
  friends: [
    {
      content: `חברים "אחים" - צוות רייצ'ל`,
      location: '/galleries/friends/brothersInScool',
    },
    // { content: 'חברים "אחים" 2009-19', location: '/galleries/friends/brothers' },
    { content: 'חברים נוספים', location: '/galleries/friends/otherFriend' },
  ],
  travel: [
    { content: 'הטיול הגדול 2008-9', location: '/galleries/travel/bigTrip' },
    { content: 'אפריקה 2010', location: '/galleries/travel/africa' },
    { content: 'אירופה 2011-2', location: '/galleries/travel/europeFirst' },
    { content: 'גיאורגיה 2013', location: '/galleries/travel/georgia' },
    {
      content: 'אוסטרליה וניו זילנד 2014',
      location: '/galleries/travel/australia',
    },
    { content: 'יוון והולנד 2014', location: '/galleries/travel/greece' },
    { content: 'ארה"ב 2015', location: '/galleries/travel/usa' },
    { content: 'אירופה 2016-8', location: '/galleries/travel/europeSecond' },
    { content: 'דרום אמריקה 2019', location: '/galleries/travel/southAmerica' },
  ],
  hobbies: [
    { content: 'ספורט', location: '/galleries/hobbies/sport' },
    { content: 'צופים', location: '/galleries/hobbies/scouts' },
    { content: 'מידברן', location: '/galleries/hobbies/midburn' },
  ],
  dayAfter: [
    { content: 'הספדים', location: '/dayAfter/eulogy' },
    { content: 'אירועים לזכרו', location: '/dayAfter/inHisMemory' },
    { content: 'הנצחה', location: '/dayAfter/memorial' },
    { content: 'סרטונים', location: '/dayAfter/videos' },
    {
      content: 'חברים כותבים - קטעים נוספים',
      location: '/dayAfter/friendsRemember',
    },
  ],
};
