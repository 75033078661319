import React, { Fragment } from 'react';
import DropdownLink from './DropdownLink';

const DropdownList = ({ topic }) => {
	const topicLength = topic.length;
	const dropdownLink = topic.map((item,index) => {
		const last = (index+1 === topicLength) ? true : false;
		const { content, location } = item;
		return <DropdownLink content={content} location={location} last={last} key={Math.random()} />;
	});

	return <Fragment>{dropdownLink}</Fragment>;
};

export default DropdownList;
