import React from 'react';
// import img from './footer_image.png';
// import img from 'https://res.cloudinary.com/yonatan-memorial/image/upload/v1620476787/General/footer_image_jglysr.png';
import './footer.css';
// import Newsletter from '../website_widgets/Newsletter';

const Footer = () => {
  return (
    <footer className='page-footer'>
      <div className='page-footer__main'>
        <div className='page-footer__img-container'>
          <img
            src='https://res.cloudinary.com/yonatan-memorial/image/upload/q_auto/v1620476787/General/footer_image_jglysr.png'
            alt=''
          />
        </div>
        <div className='page-footer__text'>קצר פה כל כך האביב</div>
      </div>
      {/* <div className='newsletter'>
				<Newsletter />
			</div> */}
    </footer>
  );
};

export default Footer;
