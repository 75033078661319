import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { navDropdownLinks } from '../../api/navbarData';
// import GoogleAuth from '../website_widgets/GoogleAuth';
import DropdownList from './DropdownList';
import './header.css';

const Header = () => {
  const { lifeStory, friends, travel, hobbies, dayAfter } = navDropdownLinks;

  let dropdownRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setShowBar(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const [showBar, setShowBar] = useState(false);
  const handleShowBar = () => {
    setShowBar(!showBar);
  };

  const handleMouseLeave = () => {
    setShowBar(false);
  };

  let navItems = showBar
    ? 'collapse navbar-collapse main-nav__items show'
    : 'collapse navbar-collapse main-nav__items';

  return (
    <nav className='navbar navbar-expand-lg fixed-top main-nav'>
      <div className='container'>
        <div className='navbar-brand'>
          <Link to='/'>יונתן גורן 1986-2019</Link>
        </div>
        <div className='main-nav__button'>
          <button
            onClick={handleShowBar}
            className='navbar-toggler custom-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navLinks'
            aria-controls='navLinks'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>
        </div>
        <div
          className={navItems}
          onMouseLeave={handleMouseLeave}
          ref={dropdownRef}
        >
          <ul className='navbar-nav'>
            <li className='nav-item dropdown'>
              <Link
                onClick={handleShowBar}
                className='nav-link dropdown-toggle'
                to='/galleries/lifeStory'
                id='navbarDropdown'
                role='button'
                aria-haspopup='true'
                aria-expanded='false'
              >
                סיפור חיים
              </Link>
              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <DropdownList topic={lifeStory} />
              </div>
            </li>
            <li className='nav-item dropdown'>
              <Link
                onClick={handleShowBar}
                className='nav-link dropdown-toggle'
                to='/galleries/friends'
                id='navbarDropdown'
                role='button'
                aria-haspopup='true'
                aria-expanded='false'
              >
                עם חברים
              </Link>
              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <DropdownList topic={friends} />
              </div>
            </li>
            <li className='nav-item dropdown'>
              <Link
                onClick={handleShowBar}
                className='nav-link dropdown-toggle'
                to='/galleries/travel'
                id='navbarDropdown'
                role='button'
                aria-haspopup='true'
                aria-expanded='false'
              >
                טיולים בעולם
              </Link>
              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <DropdownList topic={travel} />
              </div>
            </li>
            <li className='nav-item dropdown'>
              <Link
                onClick={handleShowBar}
                className='nav-link dropdown-toggle'
                to='/galleries/hobbies'
                id='navbarDropdown'
                role='button'
                aria-haspopup='true'
                aria-expanded='false'
              >
                עיסוקים ותחביבים
              </Link>
              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <DropdownList topic={hobbies} />
              </div>
            </li>
            <li className='nav-item dropdown'>
              <Link
                onClick={handleShowBar}
                className='nav-link dropdown-toggle'
                to='/dayAfter'
                id='navbarDropdown'
                role='button'
                aria-haspopup='true'
                aria-expanded='false'
              >
                לזכרו
              </Link>
              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <DropdownList topic={dayAfter} />
              </div>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/contact' onClick={handleShowBar}>
                ליצירת קשר
              </Link>
            </li>
          </ul>
          {/* <ul className="navbar-nav mr-auto">
						<li className="nav-item">
							<Link className="nav-link" to="/contact">
								צור קשר
							</Link>
						</li>
						<li className="nav-item">
							<GoogleAuth />
						</li>
					</ul> */}
        </div>
      </div>
    </nav>
  );
};

export default Header;
