import React, { Fragment } from 'react';
import CreateSections from './CreateSections';

const SubTopicContent = ({ topic, subTopic }) => {
	const subTopicObj = topic.topicImages.find((obj) => {
		return obj.category === subTopic;
	});

	//creates new array with all sub-array elements concatenated into it recursively up to the specified depth.
	let subTopicImages = subTopicObj.images.flat();

	//creates array with longDesc and image array of sections
	let sections = subTopicObj.description.map((e, i) => [e, subTopicObj.images[i]]);
	// console.log(sections);

	//sending each section to CreateSections for render
	const renderSections = sections.map((section, index) => {
		return (
			<CreateSections
				index={index}
				description={section[0]}
				images={section[1]}
				subTopicImages={subTopicImages}
				key={Math.random()}
			/>
		);
	});

	return <Fragment>{renderSections}</Fragment>;
};

export default SubTopicContent;
