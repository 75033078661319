//getting this after chossing any SubTopic gallery from navbar or main topic-> sub topic

import React from 'react';
import SubTopicContent from './SubTopicContent';
import './subTopicPage.css';

const polarSteps = (match) => {
  if (match.url === '/galleries/travel/southAmerica') {
    return (
      <div id='polarsteps'>
        <h3>יומן המסע של יונתן</h3>
        {/* <iframe title='polarsteps' width="640" height="480" src="https://www.polarsteps.com/YonatanGoren/1480884-vintage-south-america?embed=true"></iframe> */}
        <iframe
          title='polarsteps1'
          src='https://www.polarsteps.com/YonatanGoren/1480884-vintage-south-america'
        ></iframe>
      </div>
    );
  }
  return;
};

const SubTopicPage = ({ match, topic }) => {
  const subTopic = topic.topicImages.find(
    ({ category }) => category === match.params.subTopic,
  );

  return (
    <div id='SubTopicPage' className='container-fluid'>
      {/* SubTopicPage */}
      <h2 id='SubTopicPage-title'>{subTopic.title}</h2>
      <div className='subTopicPage__sections'>
        <SubTopicContent topic={topic} subTopic={match.params.subTopic} />
      </div>
      {polarSteps(match)}
    </div>
  );
};

export default SubTopicPage;
